export const themes = {
  light: {
    primary: '#007bff',
    background: '#ffffff',
    surface: '#f8f9fa',
    text: '#333333',
    border: '#dee2e6',
    error: '#dc3545',
    'error-dark': '#bd2130',
    success: '#28a745',
    'surface-hover': '#e9ecef'
  },
  dark: {
    primary: '#0d6efd',
    background: '#121212',
    surface: '#1e1e1e',
    text: '#ffffff',
    border: '#404040',
    error: '#dc3545',
    'error-dark': '#bd2130',
    success: '#28a745',
    'surface-hover': '#2d2d2d'
  },
  forest: {
    primary: '#2e7d32',
    background: '#f1f8e9',
    surface: '#ffffff',
    text: '#1b5e20',
    border: '#81c784',
    error: '#c62828',
    'error-dark': '#b71c1c',
    success: '#2e7d32',
    'surface-hover': '#e8f5e9'
  }
};